<template>
  <div class="center">
    <div style="padding-left:50px;padding-right:50px"
         class="imgbox">
      <div>
        <img style="width:100px;"
             src="@/assets/success.png" />
        <p>你好，你已登记成功，请保持手机畅通并严格按照本地防疫要求做好防护，{{map}}联系电话：{{phone}}。</p>
      </div>
      <p>提交成功!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'success',
  data () {
    return {
      map: '',
      phone: '',
      timer: 0
    };
  },
  created () {
    this.Adata()
  },
  mounted () {

  },
  methods: {
    Adata () {
      let commitmentId = localStorage.getItem('community_identity_id')
      const timer = (new Date()).getTime();
      if (timer > this.timer + 5000) {
        this.timer = timer
        this.post(commitmentId)
      } else {
        this.timer = timer
      }
    },
    post(commitmentId) {

      this.$axios.get('/miniProgram/community/info', {
        headers: {
          'Content-Type': 'application/json',
          'X-COMMUNITY': commitmentId,
        }
      }).then(res => {
        this.map = res.data.data.name
        this.phone = res.data.data.phone
      }).catch(err => {
        console.log(err);
      })
    }
  }
};
</script>

<style scoped >
.imgbox {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  flex-direction: column;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 700px;
}
</style>
