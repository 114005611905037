<template>
    <div>
        <!-- 立即办理 -->
    <div class="all">
    <div class="top" >
        <p>来(返)绵人员自助登记</p>
    </div>
    <div style="padding:20px">
      <div class="felxbpx" style="padding-bottom:20px">
         <van-icon name="music-o" />
         <div class="felxbpx" >
           <span class="bloder" style="margin-left:1px">疫情防控播告:</span><van-icon name="play-circle-o" color="#1989fa" size="20" @click="isPlayAudio" v-if="!auico" /><van-icon name="pause-circle-o" size="20" color="#1989fa"  @click="isPlayAudios"  v-if="auico"/>
         </div>
      </div>
      <span class="bloder" style="color:rgb(72,159,243);">关于来（返）绵人员防疫情况自助申报的通知</span>
      <p class="textIndex bloder">永兴镇各村（社区）居民、企事业单位员工、来绵人员：</p>
      <div @click="isshows(true)" v-if="!isshow" class="moreBox">查看更多.....</div>
      <div  :class="[isshow == true ? 'infos' : 'info']">
      <p class="textIndex">为扎实做好来（返）绵人员的疫情防控工作，全力确保我镇病源不输入、疫情不反弹。进一步简化来绵返绵人员摸排工作流程，落实“科学防控、精准施策”要求，实现让信息多跑路，群众少跑腿，按照绵阳高新区永兴镇应对新冠肺炎疫情指挥部统一安排部署，新冠肺炎疫情防控自助申报系统正式上线运行。该系统严格按照公安机关和统计部门有关规定对相关信息进行妥善管理和规范运用，确保安全可靠，可放心使用。现就做好来（返）绵人员防疫申报的有关事项通知如下：</p>
      <p class="textIndex">一、所有来绵返绵人员当日必须主动通过电话向所在的单位、小区物业、村组（社区）申报登记。</p>
      <p class="textIndex">二、全镇居民有义务对外来人员进行监督，敦促外来人员按规定申报登记，举报可疑现象。</p>
      <p class="textIndex">三、来绵返绵人员可通过“来（返）绵人员自助登记”系统，进行在线申报登记，登记前，请先对个人的实时“天府健康码”、“大数据行程卡”进行截图保存后开始自助填报。</p>
      <p class="bloder">特此通知。</p>
      <div class="">
         <span style="" class="textright bloder">绵阳高新区永兴镇应对新冠肺炎疫情指挥部</span>
         <p  class="textright">2021年7月22日</p>
      </div>
      </div>
      <div @click="isshows(false)" v-if="isshow" class="moreBox" style="color: red">收起</div>
    </div>

    <p>准备好以下材料</p>
    <div class="cou">
      <div><span>1</span><div style="line-height:80px;">身份证信息</div><p></p></div>
      <div  @click="showPopup(1)"><span>2</span><div >健康码截图</div><p> (点击直接进入天府健康通小程序)</p></div>
      <van-popup v-model="show[1].show" :style="{ width: '60%',height:'60%' }">
          <img  width="100%"  height="86%"  src="../assets/anqvan.jpg"/>
          <div  ><van-button round  size="normal" type="primary" @click="goTft()">前往天府通小程序</van-button></div>
      </van-popup>

      <div  @click="showPopup(2)"><span>3</span><div >行程卡截图</div><p>(点击直接进入大数据行程卡小程序)</p></div>
      <van-popup v-model="show[2].show" :style="{ width: '60%',height:'60%' }">
          <img  width="100%"  height="86%"  src="../assets/map.jpg"/>
          <div ><van-button round  size="normal" type="primary" @click="XCK()">前往行程卡小程序</van-button></div>
      </van-popup>

      <div  @click="showPopup(3)"><span>4</span><div>核酸报告</div><p>(拍照上传核酸报告图片或点击直接进入天府健康通小程序截图)</p></div>
      <van-popup v-model="show[3].show" :style="{ width: '80%' }">
          <img  width="100%"  height="100%"  src="../assets/hesuan.png"/>
          <div ><van-button round  size="normal" type="primary" @click="goTft()">前往天府通小程序</van-button></div>
      </van-popup>
      <span @click="clitype">立即登记</span>
    </div>
  </div>
    </div>
</template>

<script>
export default {
  props:{
    type:{
      typeof:Number
    }
  },
    data() {
        return {
          auico:false,
          isshow:false,
          data:false,
          show:[{
            show:false
          },
          {
            show:false
          },
          {
            show:false
          },
          {
            show:false
          }],
        };
    },
    created() {

    },
    mounted() {

    },
    methods: {
      isPlayAudio(){
        this.auico = !this.auico
         this.playAudio(1)

      },
      isPlayAudios(){
        this.auico = !this.auico
        this.playAudio(2)
      },
      isshows(i){
        this.isshow = i
      },
      subimt(){
        this.$emit('getType','1')
      },
      showPopup(ind){
      this.show[ind].show = true;
    },
    clitype(){
      this.$emit('clitype','1')
    },
    goTft(){
      let appid = 'wx34b9f47827e4801d'
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({url: `/pages/active/Tianfu/index?appid=${appid}`})
    },
    XCK(){
      let appid = 'wx8f446acf8c4a85f5'
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({url: `/pages/active/Tianfu/index?appid=${appid}`})
    }
    }
};
</script>

<style scoped>
.felxbpx{
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-align: center;
}
.moreBox{
  display: flex;
  justify-content: center;
  text-align: center;
}
.top{
  background: rgb(89,193,131);
  text-align: center;
  padding: 20px;
  color: aliceblue;
  font-weight: bolder;
}
.top>p{
  font-size: 27px;
  font-style:  italic;
  /* text-shadow: 2px 2px 2px rgb(209, 209, 206); */
  margin: 10px;
}
.top>div{
  font-size: 20px;
}
.cou{
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.all>p{
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
}
.cou>div{
  width: 150px;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px rgb(209, 209, 206);
  margin-top: 5px;}
.cou>div>div{
  font-size: 16px;
  text-align: center;
}
.cou>div>span{
  color: white;
  font-weight: bolder;
  display: inline-block;
  padding: 5px 20px;
  background:orange;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.cou>div>p{
  color: brown;
  font-size: 14px;
}
.cou>span{
  display: block;
  background: #67d274;
  margin-top:10px;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  text-align: center;
  color: aliceblue;
  font:20px bold ;
}


.bloder{
  font-weight: bolder;
}


.textIndex{
  text-indent: 20px;
}
.textright{
  text-align: right;
}


.info{
	display: none;
	top: 10%;
	left: 32%;
	background-color: white;
	z-index: 1002;
	overflow: auto;
	animation: anim 2s;
}



.infos{
	display: block;
	top: 10%;
	left: 32%;
	background-color: white;
	z-index: 1002;
	overflow: auto;
	animation: anim 2s;
}



@keyframes anim {
	0% {opacity: 0;}
	100%{opacity:1;}
}

</style>
