<template>
  <div id="app">
    <audio src="" id="eventAudio"></audio>
    <Home v-if="type == 0"
          :type="type"
          @clitype="clitype" />
    <HelloWorld msg="Welcome to Your Vue.js App"
                v-if="type == 1"
                @success='success' />
    <Success v-if="type == 2" />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Home from './components/home.vue'
import Success from './components/success.vue'
export default {
  name: 'App',
  components: {
    HelloWorld,
    Home,
    Success
  },
  data () {
    return {
      type: 0
    }
  },
  created () {
    console.log('key', this.getUrlParams('dept'))
    let http = window.location.href
    if (http.indexOf('dept=') !== -1) {
      localStorage.setItem('community_identity_id', this.getUrlParams('dept'))
    } else {
      localStorage.setItem('community_identity_id', 13)
    }
    if (http.indexOf('token') !== -1) {
      localStorage.setItem('community_x_token', this.getToken())
    } else {
      localStorage.setItem('community_x_token', 'eyJvcGVuaWQiOiIiLCJkZXB0IjoxMywiaWQiOjB9.03372d4c1058d7b54c07d83d52bc531f')
    }
  },
  methods: {
    clitype (i) {
      this.type = i
    },
    success (i) {
      this.type = i
    },
    getUrlParams(key) {
      const paramter = window.location.href.split('?')[1].split('&')
      for (let i = 0; i < paramter.length; i++) {
        const item = paramter[i].split('=')
        if (item[0] === key) {
          return item[1]
        }
      }
      return ''
    },
    getToken() {
      const paramter = window.location.href.split('?')[1].split('&')
      for (let i = 0; i < paramter.length; i ++ ) {
        if (paramter[i].indexOf('token') != -1) {
          const token = paramter[i].replace('token=', '')
          return token
        }
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
