import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import { Icon } from 'vant';
import VueRouter from 'vue-router'
import audio from './assets/0729.mp3'

Vue.use(VueRouter)

import { Toast } from 'vant';
Vue.use(Toast)
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

Vue.prototype.$axios = axios


// 方法1：注册播放音频事件到Vue实例上
Vue.prototype.playAudio = (i) => {
  console.log(i)
  if (i == 1) {
    let buttonAudio = document.getElementById('eventAudio');
    buttonAudio.setAttribute('src',audio)
    buttonAudio.play()
  }
  if (i == 2) {
    let buttonAudio = document.getElementById('eventAudio');
    buttonAudio.setAttribute('src',audio)
    buttonAudio.pause()
  }
 
}

// const host = process.env.NODE_ENV === "development" ? "" : "https://yxljn.com";
// const instance = axios.create({
//   baseURL: 'https://yxljn.com'
// })

Vue.use(VueAxios, axios)

Vue.prototype.$axios = axios;
Vue.use(Icon);
Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
