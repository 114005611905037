<template>
  <div style="position: relative;margin:0;padding:0">
    <div class="topimg"></div>
    <div class="formdiv">
      <van-form ref='form'>
        <div class="title">请如实填写以下信息</div>
        <van-field v-model="form.name"
                   name="mobile"
                   label="姓名"
                   placeholder="请输入姓名"
                   :rules="[{ required: true, }]" />
        <van-field v-model="form.idcard"
                   name="身份证号码"
                   label="身份证号码"
                   placeholder="请输入身份证号码"
                   :rules="[{ required: true,  }]" />

        <van-field v-model="form.registration"
                   name="户籍所在地"
                   label="户籍所在地"
                   placeholder="请输入户籍所在地"
                   :rules="[{ required: true, }]" />
        <van-field v-model="form.familysum"
                   name="家庭人口数"
                   label="家庭人口数"
                   placeholder="请输入家庭人口数"
                   type="number"
                   :rules="[{ required: true, }]" />



        <p style=" text-align: center;font-size: 13px;font-weight: bold;margin-top: 15px;margin-bottom: 10px;">近半月去过的城市以及停留的时间<span style="color:red">(以下内容必填)</span></p>

        <div class="list"
             v-for="(item,index) in form.adds"
             :key="index">
          <div v-if="index!=0"
               style="text-align: right"
               @click="remove(index)">
            <van-icon name="cross" />
          </div>
          <van-field @click="showadd(index)"
                     v-model="item.cityname"
                     readonly
                     name="选择城市"
                     label="选择城市"
                     placeholder="请选择城市"
                     :rules="[{ required: true,  }]" />
          <van-field v-model="item.detaadd"
                     name="详细地址"
                     label="详细地址"
                     placeholder="请输入详细地址"
                     :rules="[{ required: true,  }]" />
          <van-field @click="showstime(index,'stime')"
                     v-model="item.stime"
                     readonly
                     name="到达时间"
                     label="到达时间"
                     placeholder="选择到达时间"
                     :rules="[{ required: true, }]" />
          <van-field @click="showetime(index,'etime')"
                     v-model="item.etime"
                     readonly
                     name="离开时间"
                     label="离开时间"
                     placeholder="选择离开时间"
                     :rules="[{ required: true,  }]" />
          <van-field @click="showtraffic(index)"
                     v-model="item.traffic"
                     readonly
                     name="乘坐交通"
                     label="乘坐交通"
                     placeholder="请选择乘坐交通"
                     :rules="[{ required: true,  }]" />
          <div class="listbox"
               style="width: 100%;"
               v-if="item.traffic">
            <van-field v-model="item.carno"
                       :name="cartitle"
                       :label="cartitle"
                       :placeholder="cartitle"
                       :rules="[{ required: true, message: ''+cartitle+'不能为空' }]" />

            <!-- <van-field v-model="item.seat"
                     name="座位号"
                     label="座位号"
                     placeholder="座位号"
                     :rules="[{ required: true, }]" /> -->

            <van-field v-model="item.startd"
                       name="出发地"
                       label="出发地"
                       placeholder="出发地不能为空"
                       :rules="[{ required: true,  }]" />

            <van-field v-model="item.endd"
                       name="目的地"
                       label="目的地"
                       placeholder="目的地不能为空"
                       :rules="[{ required: true,}]" />

            <van-field @click="livetime(index)"
                       v-model="item.cartime"
                       readonly
                       name="乘坐时间"
                       label="乘坐时间"
                       placeholder="选择乘坐时间"
                       :rules="[{ required: true, }]" />

          </div>
        </div>

        <div style="text-align: center;">
          <van-button type="info"
                      style="margin-top: 10px"
                      @click="add">添加城市</van-button>
        </div>
        <p style=" text-align: center;font-size: 13px;font-weight: bold;margin-top: 15px;margin-bottom: 10px;">来(返)绵目的<span style="color:red">(以下内容必填)</span></p>

        <!-- 来绵目的 -->

        <!--  -->
        <div class="list">
          <van-field @click="purposeClick()"
                     v-model="form.purpose.type"
                     readonly
                     name="来绵目的"
                     label="来绵目的"
                     placeholder="请选择来绵目的"
                     :rules="[{ required: true,}]" />

          <van-field @click="showadPurpose(index)"
                     v-model="form.purpose.cityname"
                     readonly
                     name="选择来绵地区"
                     label="选择来绵地区"
                     placeholder="请选择"
                     :rules="[{ required: true, }]" />

          <van-field v-model="form.purpose.addrss"
                     name="详细地址"
                     label="详细地址（具体到门牌号）"
                     placeholder="详细地址（具体到门牌号）"
                     :rules="[{ required: true, }]" />
          <van-field v-if="detailed"
                     v-model="form.purpose.detailedAddrss"
                     name="现居住地址"
                     label="现居住地址"
                     placeholder="请输入填写详细居住地址"
                     :rules="[{ required: true,}]" />
        </div>
        <!-- 来绵时间 -->

        <p style=" text-align: center;font-size: 13px;font-weight: bold;margin-top: 15px;margin-bottom: 10px;">来(返)绵时间<span style="color:red">(以下内容必填)</span></p>

        <div class="list">
          <van-field @click="showstimes()"
                     v-model="form.residenceTime"
                     readonly
                     name="来(返)绵时间"
                     label="来(返)绵时间"
                     placeholder="选择来(返)绵时间"
                     :rules="[{ required: true, }]" />
        </div>

        <!-- 是否停留中高风险地区 -->
        <p style=" text-align: center;font-size: 13px;font-weight: bold;margin-top: 15px;margin-bottom: 10px;">是否停留中高风险地区<span style="color:red">(以下内容必填)</span></p>
        <div class="list"
             style="padding:10px;font-size:14px">
          <van-radio-group v-model='form.riskArea' @change="onRiskAreaChange">
            <van-radio style="margin-top:20px"
                       name="是">是</van-radio>
            <van-radio style="margin-top:20px"
                       name="否">否</van-radio>
          </van-radio-group>
        </div>

        <!-- 是否接种 -->
        <p style=" text-align: center;font-size: 13px;font-weight: bold;margin-top: 15px;margin-bottom: 10px;">新冠疫苗接种情况<span style="color:red">(以下内容必填)</span></p>
        <div class="list"
             style="padding:10px;font-size:14px">
          <van-radio-group v-model='form.radio'
                           @change="onChange">
            <van-radio style="margin-top:20px"
                       name="尚未接种">尚未接种</van-radio>
            <van-radio style="margin-top:20px"
                       name="已接种第一剂次">已接种第一剂次</van-radio>
            <van-radio style="margin-top:20px"
                       name="已接种第二剂次">已接种第二剂次</van-radio>
            <van-radio style="margin-top:20px"
                       name="已接种第三剂次">已接种第三剂次</van-radio>
            <van-radio style="margin-top:20px"
                       name="已接种加强针">已接种加强针</van-radio>
          </van-radio-group>
          <van-field v-show="form.radio === '尚未接种'"
                     v-model="form.notVirusReason"
                     name="未接种理由"
                     label="未接种理由"
                     placeholder="请填写未接种理由"
                     :rules="[{ required: true,}]" />
        </div>

        <!-- 职业 -->
        <p style=" text-align: center;font-size: 13px;font-weight: bold;margin-top: 15px;margin-bottom: 10px;">人员从事行业<span style="color:red">(以下内容必填)</span></p>
        <div class="list">
          <van-radio-group v-model='form.professional'
                           @change="onChanges">
            <van-radio style="margin-top:20px"
                       name="进口冷链食品从业人员">进口冷链食品从业人员</van-radio>
            <van-radio style="margin-top:20px"
                       name="口岸直接接触进口货物从业人员">口岸直接接触进口货物从业人员</van-radio>
            <van-radio style="margin-top:20px"
                       name="隔离场所工作人员">隔离场所工作人员</van-radio>
            <van-radio style="margin-top:20px"
                       name="交通运输工具从业人员">交通运输工具从业人员</van-radio>

            <van-radio style="margin-top:20px"
                       name="其他相关重点人群">其他相关重点人群</van-radio>
            <van-radio style="margin-top:20px"
                       name="以上都不是">其他</van-radio>
          </van-radio-group>
          <van-field v-show="form.professional === '以上都不是'"
                     v-model="form.zhiye"
                     name="请输入您的职业"
                     label="所属行业"
                     placeholder="请输入您的职业"
                     :rules="[{ required: true}]" />
        </div>

        <!-- 健康码上传 -->
        <p style=" text-align: center;font-size: 13px;font-weight: bold;margin-top: 15px;margin-bottom: 10px;">健康码 行程卡上传<span style="color:red">(以下内容必传)</span></p>
        <div class="list">
          <div class="felxs"
               style="margin-top:20px">
            <span style="float:left"><span style="color:red">健康码</span>(请上传天府通小程序上的健康码截图)</span>
            <van-uploader v-model="form.fileList"
                          :after-read="afterRead" />

            <span style="float:left"><span style="color:red">行程卡</span>(请上传大数据行程卡小程序上的行程卡截图)</span>
            <van-uploader v-model="form.fileLists"
                          :after-read="afterReads" />

            <!-- 如果为红色或者黄色 择需要去选择是否检测核酸 -->
            <div v-if="Whether = true">
              <p>是否检测核酸（48小时内）</p>
              <van-radio-group v-model='form.whethers'
                               @change="whether">
                <van-radio style="margin-top:20px"
                           name="是">是</van-radio>
                <van-radio style="margin-top:20px"
                           name="否">否</van-radio>
                <van-radio style="margin-top:20px"
                           name="已检测,未出结果">已检测,未出结果</van-radio>
              </van-radio-group>
            </div>

            <div v-if="detection">
              <p>请选择您检测的时间：</p>
              <van-field @click="showDetectionTime()"
                         v-model="form.detectionTime"
                         readonly
                         name="核酸检测时间"
                         label="核酸检测时间"
                         placeholder="选择核酸检测时间"
                         :rules="[{ required: true, }]" />
            </div>
            <div v-if="nucleic"
                 class="felxs">
              <span style="float:left"><span style="color:red">48小时内核酸检测报告</span>(请上传48小时内核酸检测报告照片或天府健康通小程序上核酸结果截图)</span>
              <van-uploader v-model="form.nucleicList"
                            :after-read="afterNucle"
                            :max-count="10"
                            style="margin-top:20px" />

            </div>
            <div v-if="nucleicPhone">
              <h1 style="color:red">请尽快前往就近的核酸检测点进行检测</h1>
            </div>
            <!-- 健康码 -->
            <img width="100"
                 height="100"
                 :src="wheelImg"
                 id="imgs"
                 v-show="false" />

            <img width="100"
                 height="100"
                 :src="wheelImgs"
                 id="imgs2"
                 v-show="false" />
          </div>
        </div>

        <!-- 目前体温 -->
        <p style=" text-align: center;font-size: 13px;font-weight: bold;margin-top: 15px;margin-bottom: 10px;">当前体温<span style="color:red">(以下内容必填)</span></p>
        <div class="list">
          <p style="text-align: left;font-weight: bold;margin-top: 15px;margin-bottom: 10px;">1.当前是否有新冠肺炎相关症状？</p>
            <van-radio-group v-model='form.Covids'
                              @change="Covidsa">
              <van-radio style="margin-top:20px"
                          name="是">是</van-radio>
              <van-radio style="margin-top:20px"
                          name="否">否</van-radio>
            </van-radio-group>
          <p style="text-align: left;font-weight: bold;margin-top: 15px;margin-bottom: 10px;">2.当前体温(℃)</p>
             <van-radio-group v-model='form.temperature'
                               @change="whereTemperature">
                <van-radio style="margin-top:20px"
                           name="正常（体温小于37.3℃）">正常（体温小于37.3℃）</van-radio>
                <van-radio style="margin-top:20px"
                           name="发烧（大于等于36.3(℃)）">发烧（大于等于37.3℃）</van-radio>
              </van-radio-group>
        </div>

        <!-- 手机验证码 -->
        <div class="list">
<!--           <div class="yanmaBox ">-->
<!--            <van-field v-model="form.imgTele"-->
<!--                       placeholder="请输入图片验证码"-->
<!--                       :rules="[{ required: true, }]" />-->
<!--            <img :src="imgs"-->
<!--                 alt=""-->
<!--                 @click="getImg"-->
<!--                 style="width:150px;height:50px">-->
<!--          </div>-->

          <div class="yanmaBox">
            <van-field v-model="form.Telephone"
                       name="联系电话"
                       label="联系电话"
                       placeholder="请输入联系电话"
                       :rules="[{ required: true, }]" />
            <van-button :loading='false'
                        type="info"
                        :loading-text="loadding"
                        size="small"
                        style="width:20%"
                        @click="loaddingClicl">{{loadding}}</van-button>
          <!-- 加载中... -->
        </div>


        <van-field v-model="verificationPhone"
                   name="手机验证码"
                   label="手机验证码"
                   placeholder="请输入手机验证码"
                   :rules="[{ required: true,  }]" />
          <div style="font-size:14px; color: #909399; padding-left: 10px; padding-top: 5px;">如果未接收到验证码，请检查手机是否拦截</div>
        </div>


        <div class="list"
             style="padding:10px;font-size:14px">
          <div class="textBtn">
            <van-radio-group v-model="form.commitment">
              <van-radio name="2" />
            </van-radio-group>
            <span style="color:red;maring-left:20px">我承诺以上所有内容均为自身真实情况，由于信息填报有误导致的后果，将由填报人负责</span>
          </div>
        </div>
        <div class="btnBox"
             style="margin-top:30px;padding-bottom:50px">
          <van-button round
                      block
                      type="primary"
                      native-type="submit"
                      style="width:60%;"
                      @click="subimt">提交登记</van-button>
        </div>
      </van-form>

      <!-- 底部弹出窗口 -->

      <!--     过去停留时间 选择城市-->
      <van-popup v-model="addshow"
                 position="bottom">
        <van-area title="选择城市"
                  :area-list="areaList"
                  :columns-num="3"
                  @confirm="surearea"
                  @cancel="cancelarea" />
      </van-popup>

      <van-popup v-model="addshows"
                 position="bottom">
        <van-area title="选择来绵地区"
                  :area-list="areaLists"
                  :columns-num="3"
                  @confirm="sureareaPurpose"
                  @cancel="cancelarea" />
      </van-popup>

      <!--  选择来绵时间 -->
      <van-popup v-model="timeshowProus"
                 position="bottom">
        <van-datetime-picker @confirm="confirmtimeLM"
                             @cancel="canceltimeLM"
                             v-model="currentTime"
                             :min-date="minDate"
                             type="datetime"
                             title="选择来绵时间" />
      </van-popup>

      <!-- 选择核酸时间 -->
      <van-popup v-model="showDetetime"
                 position="bottom">
        <van-datetime-picker @confirm="canceltimeDetectime"
                             @cancel="canceltimeDet"
                             v-model="currentTime"
                             :min-date="minDate"
                             type="datetime"
                             title="选择核酸检测时间" />
      </van-popup>

      <!--      选择时间-->
      <van-popup v-model="timeshow"
                 position="bottom">
        <van-datetime-picker @confirm="confirmtime"
                             @cancel="canceltime"
                             v-model="currentTime"
                             :min-date="minDate"
                             type="datetime"
                             title="选择时间" />
      </van-popup>
      <!--      选择交通工具-->
      <van-popup v-model="trafficshow"
                 position="bottom">
        <van-picker title="标题"
                    show-toolbar
                    :columns="trafficcolumns"
                    @confirm="trafficConfirm"
                    @cancel="trafficCancel" />
      </van-popup>

      <!-- 选择来面目的 -->
      <van-popup v-model="priposeTrue"
                 position="bottom">
        <van-picker title="选择目的"
                    show-toolbar
                    :columns="purposeData"
                    @confirm="trafficPrus"
                    @cancel="trafficCancelPrus" />
      </van-popup>

      <!--      离开时间-->
      <van-popup v-model="liveshow"
                 position="bottom">
        <van-datetime-picker @confirm="confirmtimed"
                             @cancel="canceltimed"
                             v-model="currentTime"
                             :min-date="minDate"
                             type="datetime"
                             title="选择时间" />
      </van-popup>
    </div>
    <van-overlay :show="calsImgs">
      <div class="disBOX"
           style="width:100%;height:100%">文件上传中......</div>
    </van-overlay>
    <van-overlay :show="showPostLoading">
      <div class="wrapper" @click.stop>
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
export default {
  name: 'HelloWorld',
  props: ['data'],
  data () {
    return {
      showPostLoading: false,
      verificationPhone: '',// 手机验证码
      imgHash: '',// 验证码hash
      imgTele: '',// 验证码内容
      imgs: '', // 图片获取
      loadding: '获取',
      calsImgs: false,
      //裁剪组件的基础配置option
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 剪切后的图片质量（0.1-1）
        full: true, // 输出原图比例截图 props名full
        outputType: 'png', // 裁剪生成额图片的格式
        canMove: true,  // 能否拖动图片
        original: false,  // 上传图片是否显示原始宽高
        canMoveBox: true,  // 能否拖动截图框
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 740, // 默认生成截图框宽度
        autoCropHeight: 400, // 默认生成截图框高度
        fixedBox: true // 截图框固定大小
      },
      wheelImg: '',
      wheelImgs: '',
      cartitle: '',
      areaList,  //全国城市数据
      areaLists: {
        province_list: {
          510000: "四川省"
        },
        city_list: {
          510700: "绵阳市"
        },
        county_list: {
          510703: "涪城区",
          510704: "游仙区",
          510705: "安州区",
          510722: "三台县",
          510723: "盐亭县",
          510725: "梓潼县",
          510726: "北川羌族自治县",
          510727: "平武县",
          510781: "江油市",
          510791: "高新区",
        }
      },
      addshow: false,  // 交通城市弹窗
      addshows: false, // 来绵目的城市弹窗
      addindex: 0,     //城市索引
      timeshow: false,//时间弹窗
      timetype: '',  //时间类型
      currentTime: '',  //暂存时间
      trafficshow: false,  //交通弹窗
      liveshow: false,  //离开时间
      priposeTrue: false,// 目的弹窗
      detailed: false,// 来绵详细地址
      timeshowProus: false,// 来绵时间
      showDetetime: false,// 核酸检测时间
      nucleic: false,// 核酸检测二维码上传
      Whether: false,// 如果二维码不是绿色那么请上传核酸检测报告
      nucleicAcid: false,// 以检测核酸报告上传核酸检测码
      nucleicPhone: false,// 没有检测核酸报告，提示请尽快去检测核酸
      detection: false, // 以检测核酸报告
      minDate: new Date(2021, 0, 1),
      radio: '',
      gongju: '',
      //城市发送表单
      form: {
        notVirusReason: '', // 未接种理由
        zhiye: '', // 职业
        familysum: '', // 家庭人口数
        name: '',// 名称
        idcard: '',// 身份证
        Telephone: '',// 电话号码
        riskArea: '',
        // 添加书白哦党
        adds: [{
          citycode: '',
          cityname: '',
          detaadd: '',
          stime: '',
          etime: '',
          traffic: '',
          carno: '',
          // seat: '',
          startd: '',
          endd: '',
          cartime: ''
        }],
        // 目的
        purpose: {
          citycode: '',// 选择结果数组对象
          cityname: '',// 城市名称
          addrss: '', // 详细地址
          detailedAddrss: '',// 现居住地址
          type: '', // 来面目的
        },
        residenceTime: '',// 来绵时间
        radio: '',//是否接种
        temperature: '',// 当前体温
        fileList: [],// 健康码
        fileLists: [],// 行程卡
        nucleicList: [],// 核酸检测结果
        commitment: '',// 用户承诺
        professional: '',// 所属职业（除了以上都不是，都是高危职业）
        whethers: '',// 是否检测过核酸 (是，否，以检测，未出结果) 三个结果
        detectionTime: '',// 核酸检测时间
        registration: '',// 户籍所在地
        Covids:'',//当前是否有新冠肺炎相关症状

      },

      //交通工具
      trafficcolumns: ['公共汽车', '火车(高铁、动车)', '船只', '飞机', '自驾', '出租车(网约车)'],
      // 目的
      purposeData: ['回家', '务工', '探亲', '出差', '旅游', '其他'],
      // 是否接种新冠疫苗
      vaccination: ['是否接种新冠疫苗', '还未接种完成,只接种了一剂或两剂', '已完成全部剂数接种'],
      rules: [
        {
          // 是否必填
          required: true,
          message: '错误信息',
          trigger: "onBlur/onChange"
        },
        {
          // 自定义表单校验
          validator: value => {
            console.log(value);
            // true:验证通过
            // false:验证不通过
            return value
          }, message: "错误信息",
          trigger: "onBlur/onChange"
        }
      ]

    };
  },
  created () {
    this.getImg()
    let form = localStorage.getItem('community_identity_tmp')
    let cartitle = localStorage.getItem('community_identity_tmps')
    console.log('getData', form)
    try {
      cartitle = JSON.parse(cartitle)
      form = JSON.parse(form)
      if (!form && typeof (form) != "undefined" && form != 0) {
        console.log(111)
        return
      }
      console.log(form)
      this.cartitle = cartitle
      this.recoveryForm(form)
      console.log('form', JSON.parse(JSON.stringify(this.form)))
    } catch (e) {
      console.log(e)
    }
  },
  watch: {
    'form': {
      handler () {
        this.saveTmpData()
        console.log('formChange', JSON.parse(JSON.stringify(this.form)))
      },
      deep: true
    },
    'cartitle': {
      handler () {
        this.ifGongju()
      },
      deep: true
    },
    'form.professional': {
      handler () {
        this.getNucleic()
      }
    },
    'form.whethers': {
      handler () {
        this.getWhether()
      }
    },
    'form.radio': {
      handler(nv, ov) {
        console.log('radio发生变化', nv, ov)
      }
    }

  },
  methods: {
    recoveryForm(form) {
      for (const formKey in form) {
        if (typeof this.form[formKey] !== undefined) {
          this.form[formKey] = form[formKey]
        }
      }
    },
    getImg () {
      this.$axios.get('/miniProgram/SMS/captcha').then(res => {
        this.imgs = res.data.data.code
        this.imgHash = res.data.data.hash
      })
    },
    loaddingClicl () {
      if (this.form.Telephone === '') {
        this.$toast.success('请输入手机号码')
        return
      }
      this.$axios.post('/miniProgram/SMS/super', {
        code: this.form.imgTele,
        hash: this.imgHash,
        phone: this.form.Telephone,
      }, {
        headers: {
          'X-COMMUNITY': localStorage.getItem('community_identity_id')
        }
      }).then(res => {
        console.log(res);
        this.$toast.success(res.data.data.msg)
      })

    },
    async saveTmpData () {
      localStorage.setItem('community_identity_tmp', JSON.stringify(this.form))
    },
    async subimt () {
      const form = JSON.parse(JSON.stringify(this.form))
      console.log('form', form)

      const result = await this.$axios.post('/miniProgram/SMS/valid', {
        code: this.verificationPhone,
        phone: this.form.Telephone,
      })

      if (result.data.data !== undefined && result.data.data.code !== undefined && result.data.data.code === 1) {
        this.$toast.success('验证码错误');
        return
      }
      if (result.data.data.msg !== undefined) {
        this.$toast.success(result.data.data.msg)
        return
      }
      console.log('formlength', this.form)
      if (form.zhiye.length < 1 && form.professional === '以上都不是') {
        this.$toast.success('请输入您的职业')
        return
      }
      if (form.radio === '尚未接种' && form.notVirusReason === '') {
        this.$toast.success('请输入未接种的理由')
        return
      }
      form.radio = form.notVirusReason === '' ? form.radio : form.notVirusReason
      form.professional = form.zhiye === '' ? form.professional : form.zhiye

      if (form.familysum.length < 1) {
        this.$toast.success('请输入您的家庭成员数量')
        return
      }

      let a = this.testid(form.idcard)
      if (form.fileList.length <= 0) {
        this.$toast.success('请上传健康码')
        return
      }


      if (form.registration == '') {
        this.$toast.success('请填写户籍所在地')
        return
      }

      if (form.whethers == '是') {
        if (this.form.nucleicList.length <= 0) {
          this.$toast.success('请上传48小时内核酸检测报告照片')
          return
        }
      }

      if (form.whethers == '是' || form.whethers != '否' ) {
        if (this.form.detectionTime == '') {
          this.$toast.success('请选择您核酸检测的时间')
          return
        }
      }

      for (let i = 0; i < form.adds.length; i++) {
        if (form.adds[i].endd == '') {
          this.$toast.success('请填写目的地')
          return
        }
      }


      if (form.fileLists.length <= 0) {
        this.$toast.success('请上传行程码')
        return
      }

      if (a.status != '1') {
        this.$toast.success(a.msg)
        return
      }
      if (!(/^1[3456789]\d{9}$/.test(form.Telephone))) {
        this.$toast.success('手机号格式错误')
        return
      }
      if (form.commitment == '') {
        this.$toast.success('请先勾选承诺')
        return
      }

      if (form.name == '') {
        this.$toast.success('请填写姓名')
        return
      }

      if (form.Covidsa == '') {
        this.$toast.success('请填写新冠肺炎相关症状')
        return
      }
      console.log('this.form', form, form.radio)
      if (form.radio == '') {
        this.$toast.success('请选择接种情况')
        return
      }
      if (form.professional == '') {
        this.$toast.success('请选择职业')
        return
      }

      if (form.residenceTime == '') {
        this.$toast.success('请选择来绵时间')
        return
      }

      if (form.temperature == '') {
        this.$toast.success('请选择当前体温')
        return
      }

      if (form.Covids == '') {
        this.$toast.success('请选择当前是否有新冠肺炎相关症状')
        return
      }

      if (form.purpose.type == '') {
        this.$toast.success('请选择来返目的')
        return
      }
      if (form.purpose.addrss == '') {
        this.$toast.success('请输入来返详细地址')
        return
      }
      let commitmentId = localStorage.getItem('community_identity_id')
      this.showPostLoading = true
      const _this = this
      this.$axios.post('/miniProgram/community/returnDeclare/back',
        {
          form
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-COMMUNITY': commitmentId,
            'X-TOKEN': localStorage.getItem('community_x_token')
          }
        }).then(res => {
          _this.showPostLoading = false
          if (res.data.data.msg !== undefined && res.data.data.msg !== '') {
            this.$toast.success(res.data.data.msg)
            if (res.data.data.msg === 'success') {
              this.$emit('success', '2')
            }
            return
          }
          this.$emit('success', '2')
          this.$toast.success('提交成功')
        }).catch(() => {
          _this.showPostLoading = false
      })
    },
    testid (id) {
      // 1 "验证通过!", 0 //校验不通过
      var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
      //号码规则校验
      if (!format.test(id)) {
        return { 'status': 0, 'msg': '身份证号码不合规' };
      }
      //区位码校验
      //出生年月日校验   前正则限制起始年份为1900;
      var year = id.substr(6, 4),//身份证年
        month = id.substr(10, 2),//身份证月
        date = id.substr(12, 2),//身份证日
        time = Date.parse(month + '-' + date + '-' + year),//身份证日期时间戳date
        now_time = Date.parse(new Date()),//当前时间戳
        dates = (new Date(year, month, 0)).getDate();//身份证当月天数
      if (time > now_time || date > dates) {
        return { 'status': 0, 'msg': '出生日期不合规' }
      }
      //校验码判断
      var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);   //系数
      var b = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');  //校验码对照表
      var id_array = id.split("");
      var sum = 0;
      for (var k = 0; k < 17; k++) {
        sum += parseInt(id_array[k]) * parseInt(c[k]);
      }
      if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
        return { 'status': 0, 'msg': '身份证校验码不合规' }
      }
      return { 'status': 1, 'msg': '校验通过' }
    },
    // 文件上传
    // 健康码
    afterRead (file) {
      file.status = 'uploading';
      file.message = '上传中...';
      this.calsImgs = true
      this.form.fileList = [];
      let formData = new FormData();
      formData.append('file', file.file);
      // this.$axios.post('/api/uplaod/upload', {
      this.$axios.post('/miniProgram/upload/upload', formData).then(res => {
        this.wheelImg = res.data.data.path
        let a = this.getAverageRGB('imgs')
        console.log(a);
        this.form.fileList.push(
          {
            url: res.data.data.path
          }
        )
        this.calsImgs = false
      })
    },
    // 核酸码
    afterNucle (file) {
      file.status = 'uploading';
      file.message = '上传中...';
      this.calsImgs = true
      let formData = new FormData();
      formData.append('file', file.file);
      this.$axios.post('/miniProgram/upload/upload', formData).then(res => {
        this.form.nucleicList.push({url: res.data.data.path})
        const tmp = []
        this.form.nucleicList.forEach(item => {
            if (typeof (item.status) === 'undefined') {
              tmp.push(item)
            }
        })
        this.form.nucleicList = tmp;
        console.log('nucleic', JSON.parse(JSON.stringify(this.form.nucleicList)));
        this.calsImgs = false
      })


    },
    // 行程码
    afterReads (file) {
      file.status = 'uploading';
      file.message = '上传中...';
      this.calsImgs = true
      // 此时可以自行将文件上传至服务器
      this.form.fileLists = [];
      let formData = new FormData();
      formData.append('file', file.file);

      // this.$axios.post('/api/uplaod/upload', {
      this.$axios.post('/miniProgram/upload/upload', formData).then(res => {
        this.form.fileLists.push(
          {
            url: res.data.data.path
          }
        )
        this.calsImgs = false
      })

    },
    // 来绵目的
    showadPurpose (e) {
      this.addshows = true;
      this.addindex = e;
    },
    //弹出地址框
    showadd (e) {
      this.addshow = true;
      this.addindex = e;
    },
    // 来绵目的
    sureareaPurpose (e) {
      console.log(e);
      this.form.purpose.cityname = e.length == 2 ? e[0].name + '-' + e[1].name : e[0].name + '-' + e[1].name + '-' + e[2].name;
      this.form.purpose.citycode = e.length == 2 ? e[0].code + '-' + e[1].code : e[0].code + '-' + e[1].code + '-' + e[2].code;
      this.addshows = false;
    },
    // 交通确定城市
    surearea (e) {
      console.log(e);
      this.form.adds[this.addindex].cityname = e.length == 2 ? e[0].name + '-' + e[1].name : e[0].name + '-' + e[1].name + '-' + e[2].name;
      this.form.adds[this.addindex].citycode = e.length == 2 ? e[0].code + '-' + e[1].code : e[0].code + '-' + e[1].code + '-' + e[2].code;
      this.addshow = false;
    },
    getWhether () {
      if (this.form.whethers == '是') {
        this.nucleic = true
        this.nucleicPhone = false
        this.detection = true
      }
      if (this.form.whethers == '否') {
        this.nucleicPhone = true
        this.nucleic = false
        this.detection = false
      }
      if (this.form.whethers == '已检测,未出结果') {
        this.nucleicPhone = false
        this.nucleic = false
        this.detection = true
      }
    },
    Covidsa(event){
      this.form.Covids = event
    },
    whereTemperature(event){
      this.form.temperature = event
    },
    // 是否检测核酸报告
    whether (event) {
      this.form.whethers = event
      if (this.form.whethers == '是') {
        this.nucleic = true
        this.nucleicPhone = false
        this.detection = true
      }
      if (this.form.whethers == '否') {
        this.nucleicPhone = true
        this.nucleic = false
        this.detection = false
      }
      if (this.form.whethers == '已检测,未出结果') {
        this.nucleicPhone = false
        this.nucleic = false
        this.detection = true
      }
    },
    // 是否接种选择
    onChange (event) {
      this.form.radio = event
    },
    getNucleic () {
      if (this.form.professional == '进口冷链食品从业人员' || this.form.professional == '口岸直接接触进口货物从业人员' || this.form.professional == '隔离场所工作人员' || this.form.professional == '交通运输工具从业人员' || this.form.professional == '其他相关重点人群') {
        this.nucleic = true
        this.form.whethers = '是'
        this.nucleicPhone = false
        this.detection = false
      } else {
        this.form.whethers = '否'
        this.nucleicPhone = true
        this.nucleic = false
        this.detection = false
      }
    },
    onChanges (event) {

      this.form.professional = event
    },
    //关闭地址选择
    cancelarea () {
      this.addshow = false;
    },
    // 显示来面时间
    showstimes () {
      this.timeshowProus = true;// 来绵时间控制
    },
    // 显示核酸检测开始时间
    showDetectionTime () {
      this.showDetetime = true
    },
    //显示开始时间
    showstime (index, type) {
      this.addindex = index;
      this.timetype = type;
      this.timeshow = true;
    },
    //显示结束时间
    showetime (index, type) {
      this.addindex = index;
      this.timetype = type;
      this.timeshow = true;
    },
    // 选择来绵时间
    confirmtimeLM (e) {
      console.log('进入我的时间')
      let date = new Date(e);
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      let h = date.getHours()
      let i = date.getMinutes()
      let time = y + '年' + m + '月' + d + '日' + '-' + h + '时' + ':' + i + '分';
      this.form.residenceTime = time
      this.timeshowProus = false
    },
    canceltimeDetectime (e) {
      console.log('进入我的时间')
      let date = new Date(e);
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      let h = date.getHours()
      let i = date.getMinutes()
      let time = y + '年' + m + '月' + d + '日' + '-' + h + '时' + ':' + i + '分';
      this.form.detectionTime = time
      this.showDetetime = false
    },
    //选择时间
    confirmtime (e) {
      let date = new Date(e);
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      let h = date.getHours()
      let i = date.getMinutes()
      let time = y + '年' + m + '月' + d + '日' + '-' + h + '时' + ':' + i + '分';
      if (this.timetype == 'stime') {
        this.form.adds[this.addindex].stime = time;
      } else {
        this.form.adds[this.addindex].etime = time;
      }
      this.timeshow = false;
    },
    //关闭时间
    canceltime () {
      this.timeshow = false;
    },
    // 关闭 来绵时间
    canceltimeLM () {
      this.timeshowProus = false
    },
    canceltimeDet () {
      this.showDetetime = false
    },
    //选择离开时间
    confirmtimed (e) {
      let date = new Date(e);
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      let h = date.getHours()
      let i = date.getMinutes()
      let time = y + '年' + m + '月' + d + '日' + '-' + h + '时' + ':' + i + '分';

      this.form.adds[this.addindex].cartime = time;

      this.liveshow = false;
    },
    //关闭离开时间
    canceltimed () {
      this.liveshow = false;
    },
    // 弹出目的
    purposeClick () {
      this.priposeTrue = true;
    },
    //弹出交通工具
    showtraffic (index) {
      this.addindex = index;
      this.trafficshow = true;
    },

    // 选择目的
    trafficPrus (e) {
      console.log(e)
      this.form.purpose.type = e;
      this.priposeTrue = false;
      if (e == '务工' || e == '旅游' || e == '出差') {
        this.detailed = true
      }
    },
    ifGongju () {
      localStorage.setItem('community_identity_tmps', JSON.stringify(this.cartitle))
    },
    //选择交通工具
    trafficConfirm (e) {
      this.form.adds[this.addindex].traffic = e;
      this.trafficshow = false;
      if (e == '公共汽车') {
        this.cartitle = '车牌号';
      }
      if (e == '火车(高铁、动车)') {
        this.cartitle = '车次';
      }
      if (e == '飞机') {
        this.cartitle = '航班号';
      }
      if (e == '船只') {
        this.cartitle = '班次号';
      }
      if (e == '自驾') {
        this.cartitle = '车牌号';
      }
      if (e == '出租车(网约车)') {
        this.cartitle = '车牌号';
      }
      this.gongju = e
      this.form.adds[this.addindex].carno = '';
      // this.form.adds[this.addindex].seat = '';
      this.form.adds[this.addindex].startd = '';
      this.form.adds[this.addindex].endd = '';
      this.form.adds[this.addindex].cartime = '';
    },
    //关闭交通工具弹窗
    trafficCancel () {
      this.trafficshow = false;
    },
    // 关闭目的工具弹窗
    trafficCancelPrus () {
      this.priposeTrue = false;
    },
    //离开时间
    livetime () {
      this.liveshow = true;
    },
    //添加信息
    add () {
      this.form.adds.push(
        {
          citycode: '',
          cityname: '',
          detaadd: '',
          stime: '',
          etime: '',
          traffic: '',
          carno: '',
          // seat: '',
          startd: '',
          endd: '',
          cartime: ''
        }
      )
    },
    //删除信息
    remove (e) {
      this.form.adds.splice(e, 1)
    },
    onRiskAreaChange() {
      console.log(this.form.riskArea)
    },
    getAverageRGB (imgEl) {
      let b = document.getElementById(imgEl);   //这就是<img>的DOM
      var blockSize = 5, // only visit every 5 pixels
        defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = { r: 0, g: 0, b: 0 },
        count = 0;
      if (!context) {
        return defaultRGB;
      }
      height = canvas.height = b.naturalHeight || b.offsetHeight || b.height;
      width = canvas.width = b.naturalWidth || b.offsetWidth || b.width;
      context.drawImage(b, 0, 0);
      try {
        data = context.getImageData(0, 0, width, height);
      } catch (e) {
        return defaultRGB;
      }
      length = data.data.length;
      while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
      }
      // ~~ used to floor values
      rgb.r = ~~(rgb.r / count);
      rgb.g = ~~(rgb.g / count);
      rgb.b = ~~(rgb.b / count);
      console.log(rgb);
      return rgb;
    }

  },





}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.topimg {
  background-image: url("../assets/bg.jpg");
  width: 100%;
  height: 250px;
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.formdiv {
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  background-color: white;
  font-size: 16px;
}
.title {
  color: rgb(82, 96, 191);
  margin-top: 15px;
  margin-bottom: 15px;
}
.listbox {
  background-color: antiquewhite;
  width: 91% !important;
  margin: 10px auto;
}
.listboxp {
  text-align: left;
  font-size: 14px;
}
.list {
  border-radius: 0px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 10px rgb(0 0 0 / 30%);
  position: relative;
  padding: 10px;
}

.felxs {
  display: flex;
  flex-direction: column;
  align-content: center;
  /* padding: 12px; */
}

.btnBox {
  display: flex;
  justify-content: center;
}

.textBtn {
  display: flex;
  justify-content: flex-start;
}
.vue-cropper-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.operate-wrap {
  display: flex;
  justify-content: space-around;
}
.lf {
  display: flex;
  justify-content: space-around;
}

.rt {
  display: flex;
  justify-content: space-around;
}

.imgs {
  z-index: -11;
}
.imgs1 {
  z-index: -11;
}
p {
  text-align: center;
}

.disBOX {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.yanmaBox {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
</style>
